
// action types
export const DO_SET_ACTIVE_RECORD = 'doSetActiveRecord';
export const DO_SET_GIS_METADATA_MAPPINGS = 'doSetMappings';
export const DO_ADD_GIS_METADATA_MAPPINGS = 'doAddMappings';
export const DO_DELETE_GIS_METADATA_MAPPINGS = 'doDeleteMappings';

// mutation types
export const SET_RECORD = 'setRecord';
export const SET_METADATA_MAPPINGS = 'setMappings';
export const ADD_METADATA_MAPPINGS = 'addMappings';
export const DELETE_METADATA_MAPPINGS_BY_INDEX = 'deleteMappings';

const state = {
    record: null,
    rows: [],
};

const getters = {
    record: (state) => state.record,
    rows: (state) => state.rows,
};

const actions = {
    [DO_SET_ACTIVE_RECORD](context, record) {
        context.commit('shared/activateLoading', 'processEditor/gisIntersectionSettings', { root: true });
        context.commit(SET_RECORD, record);
        context.commit('shared/deactivateLoading', 'processEditor/gisIntersectionSettings', { root: true });
    },
    [DO_SET_GIS_METADATA_MAPPINGS](context, mappings) {
        context.commit('shared/activateLoading', 'processEditor/gisIntersectionSettings', { root: true });
        context.commit(SET_METADATA_MAPPINGS, mappings);
        context.commit('shared/deactivateLoading', 'processEditor/gisIntersectionSettings', { root: true });
    },
    [DO_ADD_GIS_METADATA_MAPPINGS](context, mappings) {
        context.commit('shared/activateLoading', 'processEditor/gisIntersectionSettings', { root: true });
        context.commit(ADD_METADATA_MAPPINGS, mappings);
        context.commit('shared/deactivateLoading', 'processEditor/gisIntersectionSettings', { root: true });
    },
    [DO_DELETE_GIS_METADATA_MAPPINGS](context, index) {
        context.commit('shared/activateLoading', 'processEditor/gisIntersectionSettings', { root: true });
        context.commit(DELETE_METADATA_MAPPINGS_BY_INDEX, index);
        context.commit('shared/deactivateLoading', 'processEditor/gisIntersectionSettings', { root: true });
    },
}

const mutations = {
    [SET_RECORD](state, record) {
        state.record = record;
    },
    [SET_METADATA_MAPPINGS](state, mappings) {
        state.rows = mappings;
    },
    [ADD_METADATA_MAPPINGS](state, mappings) {
        state.rows = [...state.rows, ...mappings];
    },
    [DELETE_METADATA_MAPPINGS_BY_INDEX](state, index) {
        state.rows.splice(index, 1);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
